import React, { useState, useEffect } from "react";
import { getSheets, loadSheet, genFakedins } from "../service";
import Menu from "../Menu";
import Item from "../Item";
import { isBlank } from "../helper";

export default function Main() {
  const [sheets, setSheets] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState({});
  const [items, setItems] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    async function loadSheets() {
      const response = await getSheets();
      await setSheets(response.data);
    }

    loadSheets();
  }, []);

  const handleChangeSelectedSheet = async event => {
    const list = sheets.filter(function(item) {
      return event.target.value === item._id;
    });
    console.log(list[0]);
    await setSelectedSheet(list[0]);
  };

  const handleChangeCheckedItems = async (include, item) => {
    let newCheckedItems = [];
    if (!include) {
      newCheckedItems = checkedItems.filter(function(iter) {
        return iter.email !== item.email;
      });
    } else {
      newCheckedItems = [...checkedItems, item];
    }
    await setCheckedItems(newCheckedItems);
  };

  const loadItems = async () => {
    const response = await loadSheet(selectedSheet);
    console.log(response.data);
    await setItems(response.data);
  };

  const fakedinChecked = async () => {
    const response = await genFakedins(checkedItems);

    let text = "";
    console.log(response.data);
    response.data.forEach(item => {
      text =
        text + "\ninscricao:" + item.inscricao + " scraper:" + item.scraper;
    });
    alert(text);
  };

  const allCheckToggle = async () => {
    let onlyNotPublished = items.filter(function(item) {
      return !item.published && !isBlank(item.linkedin);
    });

    if (!allChecked) {
      await setCheckedItems(onlyNotPublished);
    } else {
      await setCheckedItems([]);
    }
    await setAllChecked(!allChecked);
  };
  return (
    <>
      <Menu />
      <div className="container">
        <div className="input-group" style={{ marginTop: 20 }}>
          <select
            name="sheets"
            id="sheets"
            className={"custom-select"}
            defaultValue={""}
            onChange={handleChangeSelectedSheet}
          >
            <option disabled value="">
              Selecione uma Planilha
            </option>
            {sheets.map(sheet => {
              return (
                <option key={sheet._id} value={sheet._id}>
                  {sheet.title}
                </option>
              );
            })}
          </select>
          <div className="input-group-append">
            <button className={"btn btn-outline-secondary"} onClick={loadItems}>
              Carregar
            </button>
          </div>
        </div>

        {items.length > 0 && (
          <div style={{ marginTop: 40 }}>
            <div>
              {!allChecked && (
                <button
                  className="btn btn btn-primary"
                  style={{ margin: 10 }}
                  onClick={allCheckToggle}
                >
                  Selecionar Todos
                </button>
              )}
              {allChecked && (
                <button
                  className="btn btn btn-primary"
                  style={{ margin: 10 }}
                  onClick={allCheckToggle}
                >
                  Descelecionar Todos
                </button>
              )}
              {checkedItems.length > 0 && (
                <>
                  <button
                    className="btn btn btn-primary"
                    style={{ margin: 10 }}
                    onClick={fakedinChecked}
                  >
                    {" "}
                    Gerar Fakedin Selecionados
                  </button>
                  <button
                    className="btn btn btn-secondary"
                    style={{ margin: 10 }}
                  >
                    {" "}
                    Publicar Selecionados
                  </button>
                </>
              )}
            </div>
            <ul className="list-group">
              {items.map((item, index) => {
                return (
                  <Item
                    key={index}
                    item={item}
                    jobId={selectedSheet.jobId}
                    checked={allChecked}
                    published={item.published}
                    handleChangeCheckedItems={handleChangeCheckedItems}
                  />
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </>
  );
}
