import React, { useState, useEffect } from 'react';
import { create, getJobs } from '../service';
import Menu from '../Menu';

const NewSheet = () => {
  const [sheet, updateSheet] = useState({
    id: null,
    title: null,
    worksheet: null
  });
  const [jobs, setJobs] = useState([]);
  useEffect(() => {
    async function loadJobs() {
      const response = await getJobs();
      const openJobs = response.data.filter(function(job) {
        return job.status === 'open';
      });
      setJobs(openJobs);
    }

    loadJobs();
  }, []);

  const handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    updateSheet({ ...sheet, [name]: value });
  };

  const handleCreate = async () => {
    const result = await create(sheet);
    if (result.status === 200) {
      alert('Planilha criada com Sucesso!');
    } else {
      alert('Erro ao criar planilha.');
    }
    // console.log(result);
  };

  return (
    <>
      <Menu />
      <div className='card' style={{ width: '30rem', padding: 30 }}>
        <input
          type='text'
          className='form-control'
          name='title'
          placeholder='Titulo'
          onChange={handleInputChange}
          style={{ margin: 5 }}
        />
        <input
          type='text'
          className='form-control'
          name='id'
          placeholder='id'
          onChange={handleInputChange}
          style={{ margin: 5 }}
        />
        <select
          id='jobId'
          name='jobId'
          className={'custom-select'}
          onChange={handleInputChange}
          style={{ margin: 5 }}
        >
          {jobs.map(job => {
            return (
              <option key={job.code} value={job.code}>
                {job.name}
              </option>
            );
          })}
        </select>

        <input
          type='number'
          name='worksheet'
          placeholder='worksheet'
          className='form-control'
          onChange={handleInputChange}
          style={{ margin: 5 }}
        />
        <button
          className='btn btn-success'
          type='button'
          onClick={handleCreate}
        >
          Salvar
        </button>
      </div>
    </>
  );
};
export default NewSheet;
