import React from 'react';

// import { Container } from './styles';

const Menu = () => (
    <>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <a className="navbar-brand" href="/">
                Main
            </a>
            <br />
            <a className="nav-link" href="/nova-planilha">
                Adicionar Planilha <span className="sr-only">(Página atual)</span>
            </a>
        </nav>
    </>
);

export default Menu;
