// @flow
import React, { useState } from 'react';
import { genFakedin, publishItem } from '../service';
import { isBlank } from '../helper';

export default function Item(props) {
  const [checked, setChecked] = useState(false);

  const hasLinkedin = !isBlank(props.item.linkedin);

  const linkedin_username = linkedin_url => {
    if (!linkedin_url) {
      return null;
    }
    let linkedin_path = linkedin_url.split('/');

    let username =
      linkedin_path[linkedin_path.length - 1] !== ''
        ? linkedin_path[linkedin_path.length - 1]
        : linkedin_path[linkedin_path.length - 2];
    return username;
  };

  const fakedin = async item => {
    item.username = linkedin_username(props.item.linkedin);
    const response = await genFakedin(props.item);

    alert(
      'inscricao:' +
        response.data.inscricao +
        ' scraper:' +
        response.data.scraper
    );
  };

  const publish = async item => {
    if (!props.published) {
      const response = await publishItem({
        email: props.item.email,
        firstName: props.item.nome,
        lastName: props.item.sobrenome,
        jobId: props.jobId,
        phoneNumber: props.item.celular,
        linkedinUrl: props.item.linkedin,
        fakedinUrl:
          'http://delivery.byintera.com/in/' +
          linkedin_username(props.item.linkedin)
      });

      if (response.status === 200) {
        alert('Publicado com sucesso!');
      } else {
        alert('Erro ao publicar');
      }
    }
  };

  const handleChangeChecked = async event => {
    if (hasLinkedin && !props.published) {
      await setChecked(!(props.checked || checked));
      props.handleChangeCheckedItems(!(props.checked || checked), props.item);
    }
  };

  return (
    <li
      className={`list-group-item ${
        !hasLinkedin ? 'list-group-item-danger' : ''
      } ${props.published ? 'list-group-item-success' : ''} `}
    >
      <div
        style={{
          height: '100%',
          padding: '20px',
          paddingLeft: '10px',
          float: 'left'
        }}
      >
        {!props.item.published && hasLinkedin && (
          <input
            type='checkbox'
            checked={props.checked || checked}
            style={{
              transform: 'scale(1.3)'
            }}
            onChange={handleChangeChecked}
          />
        )}
      </div>
      <h5
        style={{
          display: 'inline-block',
          lineHeight: '50px'
        }}
      >
        {props.item.nome} {props.item.sobrenome} - {props.item.email} -{' '}
        {hasLinkedin && (
          <>
            <a
              style={{ fontSize: 15 }}
              target='_blank'
              rel='noopener noreferrer'
              href={`http://delivery.byintera.com/in/${linkedin_username(
                props.item.linkedin
              )}`}
            >
              Ver Fakedin
            </a>
            <a
              style={{ fontSize: 15, marginLeft: 30 }}
              target='_blank'
              rel='noopener noreferrer'
              href={`http://scraper.byintera.com/in/${linkedin_username(
                props.item.linkedin
              )}`}
            >
              Gerar via Scraper
            </a>
          </>
        )}
      </h5>

      <div
        style={{
          float: 'right'
        }}
      >
        <button
          className='btn btn-outline-primary btn-sm'
          style={{
            margin: 10
          }}
          onClick={() => fakedin(props.item)}
        >
          {' '}
          Fakedin{' '}
        </button>
        {hasLinkedin && !props.published && (
          <button
            className='btn btn-outline-secondary btn-sm'
            style={{
              margin: 10
            }}
            onClick={() => publish(props.item)}
          >
            Publicar
          </button>
        )}
      </div>
    </li>
  );
}
