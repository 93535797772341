import React from 'react';
import NewSheet from './components/NewSheet';
import Main from './components/Main';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    return (
        <BrowserRouter>
            <Switch key={'switch'}>
                <Route key={'/'} path="/" exact={true} component={Main} />
                <Route
                    key={'/nova-planilha'}
                    path="/nova-planilha"
                    exact={true}
                    component={NewSheet}
                />
            </Switch>
        </BrowserRouter>
    );
}

export default App;
