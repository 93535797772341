import axios from "axios";

const API = process.env.REACT_APP_SERVER_ADDRESS;

export const create = sheet => {
  const { id, worksheet, title, jobId } = sheet;

  const body = {
    id,
    worksheet,
    title,
    jobId
  };

  console.log(body);
  return axios.post(`${API}/nova-planilha`, body).then(function(response) {
    return response;
  });
};

export const getJobs = () => {
  return axios.get(`${API}/jobs`).then(data => {
    return data;
  });
};

export const getSheets = () => {
  return axios.get(`${API}/planilhas`).then(data => {
    return data;
  });
};

export const loadSheet = sheet => {
  return axios.get(`${API}/planilha/${sheet._id}/load`).then(data => {
    return data;
  });
};

export const genFakedin = item => {
  return axios.post(`${API}/fakedin`, item).then(data => {
    return data;
  });
};

export const genFakedins = list => {
  // console.log(list);
  return axios
    .post(`${API}/fakedins`, {
      fakedins: list
    })
    .then(data => {
      // console.log(data);
      return data;
    });
};

export const publishItem = item => {
  return axios.post(`${API}/publish`, item).then(data => {
    return data;
  });
};
